import * as React from 'react';
import { Typography } from '@mui/material';
import { Link } from 'gatsby-theme-material-ui';
import Layout from '../components/Layout';

const NotFoundPage = () => {
  return (
    <Layout>
      <title>Not found</title>
      <Typography variant="h1">Plage not found</Typography>
      <Typography variant="body1">
        Sorry{' '}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{' '}
        we couldn’t find what you were looking for.
        <br />
        {process.env.NODE_ENV === 'development' ? (
          <>
            <br />
            Try creating a page in <code>src/pages/</code>.
            <br />
          </>
        ) : null}
        <br />
        <Link to="/">Go home</Link>.
      </Typography>
    </Layout>
  );
};

export default NotFoundPage;
